<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="결재요청 정보" class="cardClassDetailForm" topClass="topcolor-orange">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-if="popupParam.approvalDocType == 'TODO'"
                  label="반려"
                  icon="remove"
                  @btnClicked="returnApprRequest" />
                <c-btn
                  v-if="popupParam.approvalDocType == 'TODO'"
                  label="결재"
                  icon="save"
                  @btnClicked="saveApprRequest" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                  <div class="col-12">
                    <c-text
                      :editable="false"
                      label="결재 업무명"
                      name="approvalTypeName"
                      v-model="data.approvalTypeName">
                    </c-text>
                  </div>
                  <div class="col-12">
                    <c-text
                      :editable="false"
                      :required="true"
                      label="결재 요청명"
                      name="approvalRequestName"
                      v-model="data.approvalRequestName">
                    </c-text>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" style="margin-top:-15px !important;">
                  <c-table
                    ref="apprline2"
                    title="회람"
                    tableId="apprline2"
                    topBorderClass="topcolor-orange"
                    :columnSetting="false"
                    :isFullScreen="false"
                    :hideBottom="true"
                    :usePaging="false"
                    :filtering="false"
                    :isExcelDown="false"
                    gridHeight="250px"
                    :columns="gridline2.columns"
                    :data="gridline2.data"
                  >
                  </c-table>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin-top:-15px !important;">
                  <c-table
                    ref="apprline"
                    title="결재선"
                    tableId="apprline"
                    topBorderClass="topcolor-orange"
                    :columnSetting="false"
                    :isFullScreen="false"
                    :hideBottom="true"
                    :usePaging="false"
                    :filtering="false"
                    :isExcelDown="false"
                    gridHeight="250px"
                    :columns="gridline.columns"
                    :data="gridline.data"
                  >
                    <template v-slot:customArea="{ props, col }">
                      <template v-if="col.name === 'approvalKindName'">
                        <span class="text-weight-bold" :class="getLineKindColor(props.row.approvalKindCd)">{{props.row.approvalKindName}}</span>
                      </template>
                      <template v-if="col.name === 'approvalStatusName'">
                        <span :class="getLineKindColor2(props.row.approvalStatusName)">{{props.row.approvalStatusName}}</span>
                      </template>
                    </template>
                  </c-table>
                </div>
            </template>
          </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="결재 상세내용" class="cardClassDetailForm displayBlockCard" topClass="topcolor-orange">
            <template slot="card-detail">
              <component :is="component" :popupParam.sync="data.approvalParamValue" />
            </template>
          </c-card>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'appr-process',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sysApprovalRequestId: '', // 결재요청 일련번호
        approvalDocType: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      component: null, // 콤포넌트
      detailTypeUrl: '',
      apprLineUrl: '',
      saveApprRequestUrl: '',
      returnApprRequestUrl: '',
      listUrl: '',
      infoUrl: '',
      data: {
        approvalTypeCd: '',  // 결재유형코드
        approvalRequestName: '',  // 결재 요청명
        approvalTypeName: '',  // 결재업무명
        approvalUrl: '',  // 결재화면 page경로
        approvalStatusCd: '',  // 결재상태
        approvalParam: '',  // 결재파라메터
      },
      gridline: {
        columns: [
          {
            name: 'approvalKindName',
            field: 'approvalKindName',
            label: '결재구분',
            align: 'center',
            style: 'width: 80px',
            type: 'custom',
            sortable: false,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            style: 'width: 120px',
            sortable: false,
          },
          {
            name: 'approvalUserName',
            field: 'approvalUserName',
            label: '성명',
            align: 'center',
            style: 'width: 80px',
            sortable: false,
          },
          {
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            label: '결재상태',
            align: 'center',
            style: 'width: 80px',
            type: 'custom',
            sortable: false,
          },
          {
            name: 'returnReason',
            field: 'returnReason',
            label: '반려사유',
            align: 'left',
            type: 'textarea',
            sortable: false,
            disableTarget: 'approvalStatusCd',
            disableCon: 'ASC0000001',
          },
          {
            name: 'approvalDt',
            field: 'approvalDt',
            label: '결재일시',
            align: 'center',
            style: 'width: 160px',
            sortable: false,
          },
        ],
        data: [],
      },
      gridline2: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            style: 'width: 40%',
            sortable: false,
          },
          {
            name: 'approvalUserName',
            field: 'approvalUserName',
            label: '성명',
            align: 'center',
            style: 'width: 40%',
            sortable: false,
          },
        ],
        data: [],
      },
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.infoUrl = selectConfig.sys.appr.info.get.url;
      this.listUrl = selectConfig.sys.appr.line.list.url;
      this.detailTypeUrl = selectConfig.sys.appr.type.get.url;
      this.apprLineUrl = selectConfig.sys.appr.line.last.url;
      this.saveApprRequestUrl = transactionConfig.sys.appr.request.update.url;
      this.returnApprRequestUrl = transactionConfig.sys.appr.request.return.url;
      this.getApprInfo();
      this.getApprLine();
    },
    getApprLine() {
      // AKC0000001 : 상신, AKC0000002 : 합의, AKC0000003 : 결재, AKC0000004 : 확인, AKC0000009 : 회람, AKC0000010 : 재상신
      this.$http.url = this.listUrl;
      this.$http.param = {
        sysApprovalRequestId: this.popupParam.sysApprovalRequestId,
      };
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        if (_result.data.length > 0) {
          let lineData1 = _result.data.filter( x => {
            return x.approvalKindCd == 'AKC0000001' || x.approvalKindCd == 'AKC0000002' || x.approvalKindCd == 'AKC0000003' || x.approvalKindCd == 'AKC0000004' || x.approvalKindCd == 'AKC0000010'
          });
          let lineData2 = _result.data.filter( x => {
            return x.approvalKindCd == 'AKC0000009'
          });
          this.gridline.data = lineData1;
          this.gridline2.data = lineData2;
        }
      },
      () => {
      });
    },
    getApprInfo() {
      this.$http.url = this.infoUrl;
      this.$http.param = {
        sysApprovalRequestId: this.popupParam.sysApprovalRequestId
      }
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.component = () => import(`@/pages${_result.data.approvalUrl}`);
        this.data.approvalParamValue = JSON.parse(_result.data.approvalParam);
        this.$_.extend(this.data.approvalParamValue, {approvalDocType: this.popupParam.approvalDocType});
      },
      () => {
      });
    },
    getLineKindColor(_cd) {
      let color = '';
      // AKC0000001 : 상신, AKC0000002 : 합의, AKC0000003 : 결재, AKC0000004 : 확인, AKC0000009 : 회람, AKC0000010 : 재상신
      switch(_cd) {
        case 'AKC0000001':
        case 'AKC0000010':
          color = 'blue-grey-4';
          break;
        case 'AKC0000002':
          color = 'green-7';
          break;
        case 'AKC0000003':
          color = 'blue-7';
          break;
        case 'AKC0000004':
          color = 'blue-grey-4';
          break;
      }
      return 'text-' + color;
    },
    getLineKindColor2(_cd) {
      let color = '';
      switch(_cd) {
        case '반려':
          color = 'text-weight-bold text-red';
          break;
      }
      return color;
    },
    returnApprRequest() {
      let apprUserInfo = {};
      let returnData = this.gridline.data.filter( x => {
        return x.approvalStatusCd == 'ASC0000001' && x.approvalUserId == this.$store.getters.user.userId
      });
      if (returnData.length > 0) {
        apprUserInfo.approvalLineNo = returnData[0].approvalLineNo;
        apprUserInfo.approvalUserId = returnData[0].approvalUserId;
        apprUserInfo.sysApprovalRequestId = returnData[0].sysApprovalRequestId;
        apprUserInfo.approvalStatusCd = 'ASC0000002'; // 반려
        apprUserInfo.returnReason = returnData[0].returnReason;
        
        if (apprUserInfo.returnReason === null || apprUserInfo.returnReason == '') {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: '반려사유를 입력하세요.', /* 반려사유를 입력하세요. */
            type: 'warning', // success / info / warning / error
          });
          return false;
        }
      }

      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '반려하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.returnApprRequestUrl;
          this.$http.type = 'PUT';
          this.$http.param = {
            approvalUser: apprUserInfo,
            approvalLineModel: this.gridline.data,
            approvalRequestModel: {
              approvalTypeCd: this.data.approvalTypeCd,
              approvalConnId: this.data.approvalConnId,
              approvalStatusCd: 'ASC0000002',
              sysApprovalRequestId: this.popupParam.sysApprovalRequestId,
            },
          };
          this.$http.request(() => {
            window.getApp.$emit('ALERT', {
              title: '안내 ', /* 안내 */
              message: '결재가 반려 처리되었습니다.', /* 결재가 반려 처리되었습니다. */
              type: 'success', // success / info / warning / error
            });
            this.$emit('closePopup');
          });
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveApprRequest() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '결재하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          // 상신자 제외 첫번째 결재자에 결재중상태로 변경
          let apprUserInfo = {};
          let apprNextUserInfo = {};
          for(var i=0; i<this.gridline.data.length; i++) {
            if (this.gridline.data[i].approvalStatusCd == 'ASC0000001' && this.gridline.data[i].approvalUserId == this.$store.getters.user.userId) { // 결재중인 사용자 결재처리
              apprUserInfo.approvalLineNo = this.gridline.data[i].approvalLineNo;
              apprUserInfo.approvalUserId = this.gridline.data[i].approvalUserId;
              apprUserInfo.sysApprovalRequestId = this.gridline.data[i].sysApprovalRequestId;
              apprUserInfo.approvalStatusCd = 'ASC9999999';
              // 다음결재 결재중으로 UPDATE
              if (this.gridline.data[i+1] !== undefined) {
                apprNextUserInfo.approvalLineNo = this.gridline.data[i].approvalLineNo + 1;
                apprNextUserInfo.sysApprovalRequestId = this.gridline.data[i].sysApprovalRequestId;
                apprNextUserInfo.approvalStatusCd = 'ASC0000001';
              }
            }
          }
          this.$http.url = this.saveApprRequestUrl;
          this.$http.type = 'PUT';
          this.$http.param = {
            approvalUser: apprUserInfo,
            approvalNext: apprNextUserInfo,
            approvalRequestModel: {
              approvalTypeCd: this.data.approvalTypeCd,
              approvalConnId: this.data.approvalConnId,
            },
          };
          this.$http.request(() => {
            window.getApp.$emit('ALERT', {
              title: '안내 ', /* 안내 */
              message: '결재가 정상적으로 처리되었습니다.', /* 결재가 정상적으로 처리되었습니다. */
              type: 'success', // success / info / warning / error
            });
            this.$emit('closePopup');
          });
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>
